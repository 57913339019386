.PaneMfaSetupEmail {
    max-width: 400px;
    width: 100%;
}

.PaneMfaSetupEmail .AppControls {
    margin: 30px 10px 0 10px;
}

.PaneMfaSetupEmailAppSubHeader {
    max-width: 400px;
}

.PaneMfaSetupEmail .PaneSubHeader {
    max-width: 400px;
    margin: 0 10px 20px 10px;
}

.PaneMfaSetupEmail .AppField {
    display: block;
    margin: 0 10px;
}

.PaneMfaSetupEmail .AppField input[type=text] {
    text-align:center;
    width: 100%;
}

.PaneMfaSetupEmail .AppField .AppFieldLabel {
    text-align:center;
}

.PaneMfaSetupEmail button {
    display: inline-block;
    max-width: 250px;
}

.PaneMfaSetupEmail img {
    margin: 0 10px;
}