.PaneLogin .AppField {
     margin-bottom: 25px;
 }

.PaneLogin .AppFieldLast .AppField {
    margin-bottom: 0;
}

.PaneLogin .AppCheckbox {
    margin-top: 25px;
}