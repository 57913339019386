.PaneRecoverSuccess {
    width: 250px;
}

.PaneRecoverSuccess .AppControls {
    margin: 30px 10px 0 10px;
}

.PaneRecoverSuccessAppHeader {
    max-width: 350px;
    align-self: center;
}