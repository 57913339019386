.AppNotice {
    display: inline-block;
    border-radius: 15px;
    font-size: 14px;
    padding: 5px 10px 5px 30px;
    text-align: left;
}

.AppNotice.error {
    background: #ffe2e2 url('../img/icon/times-circle-solid.svg') no-repeat 5px 3.5px;
    background-size: 20px 20px;
    color: #e61e1e;
}

.AppNotice.warning {
    background: #fff2c3 url('../img/icon/exclamation-circle-solid.svg') no-repeat 5px 3.5px;
    background-size: 20px 20px;
    color: #111111;
}

.AppNotice.success {
    background: #e3ffe2 url('../img/icon/check-circle-solid.svg') no-repeat 5px 3.5px;
    background-size: 20px 20px;
    color: #43a85e;
}