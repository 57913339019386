@font-face{
    font-family: "Lato-Regular";
    src: url('./font/Lato/Lato-Regular.ttf') format('truetype')
}

@font-face{
    font-family: "Lato-Bold";
    src: url('./font/Lato/Lato-Bold.ttf') format('truetype')
}

body {
    font-family: Lato-Regular, sans-serif;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
        /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
        /*sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10pt;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#root {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

input, button, select, option, a {
    font-family: Lato-Regular, sans-serif;
}

button {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}