.AppDialog {
    border: 1px solid #a0a0a0;
    background-color: white;
    border-radius: 5px;
}

.AppDialogBody {
    max-width: 300px;
    padding: 20px;
    text-align: center;
}

.AppDialogSection {
    margin-top: 20px;
}

.AppDialogBody button.rounded {
    display: inline-block;
    width: 150px;
}

.AppDialogBody .warningTriangle {
    stroke-width: 0;
}

.AppDialogBody .warningExclamation {
    stroke-width: 0;
    fill-opacity: 100%;
}