.PaneMfaEmailPaneSubHeader {
    max-width: 400px;
}

.PaneMfaEmail {
    text-align: center;
}

.PaneMfaEmailPaneSubHeader {
    margin: 0 10px 20px 10px;
}

.PaneMfaEmail button {
    display: inline-block;
    max-width: 250px;
}

.PaneMfaEmail .AppField input[type=text],
.PaneMfaEmail .AppField .AppFieldLabel {
    text-align: center;
}

.PaneMfaEmail .AppControls {
    margin: 30px 10px 0 10px;
}