.PaneChangePassword .AppField {
    margin-bottom: 25px;
}

.PaneChangePassword .AppFieldLast .AppField {
    margin-bottom: 0;
}

.PaneChangePassword button.rounded {
    display: inline-block;
    width: 220px;
}

.AppSubHeader.PaneChangePassword {
    margin-bottom: 20px;
}

.AppChangePasswordBody .AppControls {
    display: none;
}

.AppChangePasswordBody {
    display: flex;
}

.AppPasswordInput {
    width: 230px;
}

@media screen and (max-width: 540px) {
    .PaneChangePassword .AppChangePasswordBody .AppControls {
        display: block;
    }

    .PaneChangePassword .AppControls {
        display: none;
    }

    .AppPasswordValid {
        margin: 10px 0 0 0;
    }

    .AppChangePasswordBody {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .AppPasswordInput {
        width: unset;
    }
}