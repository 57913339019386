.App {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.AppAccent {
    background-color: #7f7f7f;
    width: 100%;
    height: 20px;
    min-height: 20px;
}

.AppLogo {
    margin-top: 50px;
}

.AppHeader {
    margin: 10px 10px 0 10px;
    font-size: 16px;
    text-align: center;
    font-family: Lato-Bold, sans-serif;
}

.AppSubHeader {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin: 0 10px 10px 10px;
    color: #666666;
}

.AppBody {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
}

.AppBodyCentered {
    margin: 0 auto;
}


.AppWarningIcon {
    width: 30px;
    height: 30px;
}

.AppPreProdWarning {
    position: fixed;
    bottom: 10px;
    left: 10px;
    width: 45px;
    height: 45px;
}

.AppFooter {
    font-size: 10pt;
    font-weight: bold;
    text-align: center;
    color: #666666;
    margin: 10px 10px 0 10px;
    max-width: 350px;
    align-self: center;
}

.AppCopyright {
    margin: 20px 0;
    text-align: center;
    color: #666666;
}

.AppControls {
    margin: 30px auto 0 auto;
    text-align: center;
}

.AppHelpText {
    margin-bottom: 10px;
}

.AppMessage {
    margin: 10px 10px 0 10px;
}

.AppMask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.PaneHeader {
    font-family: Lato-Bold, sans-serif;
    font-size: 16px;
    margin: 10px 0;
    text-align: center;
}

.PaneSubHeader {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;
    color: #666666;
}

.Header16 {
    font-family: Lato-Bold, sans-serif;
    font-size: 16px;
}

.Header20 {
    font-family: Lato-Bold, sans-serif;
    font-size: 20px;
}

.SubHeader14 {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
}

@media screen and (max-width: 400px), (max-height: 720px) {
    .AppLogoImg {
        width: auto;
        height: 90px;
    }
}

@media screen and (max-height: 620px) {
    .AppLogoImg {
        display: none;
    }

    .AppLogo {
        margin-top: 0;
    }
}

@media screen and (max-height: 430px) {
    .AppSubHeader, .AppHeader {
        display: none;
    }
}

@media screen and (max-height: 370px) {
    .AppFooter, .AppCopyright {
        display: none;
    }
}