.PaneSecurityQuestion .AppField input[type=text] {
    width: 100%;
}

.PaneSecurityQuestion {
    max-width: 400px;
    width: 100%;
}

.PaneSecurityQuestion .AppField {
    display: block;
}

.PaneSecurityQuestion button.rounded {
    display: inline-block;
    width: 100%;
    max-width: 250px;
}

.PaneSecurityQuestion .AppControls {
    margin: 30px 10px 0 10px;
}

.PaneSecurityQuestionQuestion {
    margin: 20px 10px 0 10px;
}

.PaneSecurityQuestionAnswer {
    margin: 20px 10px 0 10px;
}

.PaneSecurityQuestionCancel {
    margin-top: 20px;
}
