.PaneMfaSetupAppHeader svg {
    vertical-align: middle;
    margin-right: 5px;
}

.PaneMfaSetupAppHeader span {
    vertical-align: middle;
}

.PaneMfaSetupAppSubHeader {
    max-width: 400px;
    align-self: center;
}

.PaneMfaSetup .PaneSubHeader {
    max-width: 400px;
    margin: 0 auto 20px auto;
}

.PaneMfaSetup .AppControls {
    margin: 30px 10px 0 10px;
}

.PaneMfaSetup button {
    display: inline-block;
    max-width: 250px;
}

.PaneMfaSetup img {
    margin: 0 10px;
}

.PaneMfaSetupBadges {
    text-align: center;
}