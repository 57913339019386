/* fields */

.AppField {
    display: inline-block;
    border-bottom: 1px solid #333333;
    padding-bottom: 3px;
    /*height: 50px;*/
}

.AppFieldDisabled {
    border-color: #c0c0c0;
}

.AppFieldStatic input[type=text]:disabled {
    color: #111111;
}

.AppField input[type=text],
.AppField input[type=password] {
    border: 0;
    background-color: transparent;
    font-size: 16px;
    padding: 0;
    margin: 0;
}

.AppField input[type=text]:focus,
.AppField input[type=password]:focus {
    outline: none;
}

.AppField input::placeholder {
    color: #333333;
    opacity: 1;
}

.AppFieldLabel {
    text-align: left;
    font-size: 12px;
    color: #111111;
}

.AppFieldLabel.Icon {
    margin-left: 28px;
}

.AppFieldDisabled .AppFieldLabel {
    color: #c0c0c0;
}

.AppFieldDisabled input::placeholder {
    color: #c0c0c0;
}

.AppFieldIcon {
    position: relative;
    top: -1px;
    width: 20px;
    height: 20px;
    fill: #111111;
    vertical-align: middle;
    margin-right: 8px;
}

.AppFieldDisabled .AppFieldIcon {
    fill: #c0c0c0;
}

/* buttons */

button.rounded,
button.outline {
    display: block;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 400;
}
button.rounded {
    border: 0;
}
button.outline {
    border: 1px solid #7f7f7f;
}
button.rounded:focus,
button.outline:focus {
    outline: none;
}
button.rounded.active,
button.outline.active {
    cursor: pointer;
    transition: transform 0.1s linear, background-color 0.1s linear;
}
button.rounded.active {
    background-color: #7f7f7f;
    color: #e0e0e0;
}
button.outline.active {
    background-color: white;
    color: #7f7f7f;
}
button.rounded.active:hover,
button.outline.active:hover {
    transform: scale(1.1);
}
button.rounded.active:active,
button.outline.active:active {
    transform: scale(1);
}
button.rounded.active:active {
    background-color: #5f5f5f;
}
button.outline.active:active {
    background-color: #ebebeb;
}
button.rounded.disabled,
button.outline.disabled {
    background-color: #b4b4b4;
    color: #ebebeb;
    cursor: default;
}

button.link {
    border: 0;
    background-color: transparent;
    font-size: 16px;
    font-weight: 400;
}
button.link:focus {
    outline: none;
}
button.link.active {
    color: #7f7f7f;
    cursor: pointer;
}
button.link.active:active {
    color: #9f9f9f;
    cursor: pointer;
}
button.link.active:hover {
    text-decoration: underline;
}

/* links */

a {
    font-weight: 400;
    text-decoration: none;
    color: #7f7f7f;
    cursor: pointer;
}
a:focus {
    outline: none;
}
a:active {
    color: #9f9f9f;
}
a:hover {
    text-decoration: underline;
}

/* checkbox */

.AppCheckbox {
}

.AppCheckboxLabel {
    color: #333333;
}

.AppCheckbox.disabled .AppCheckboxLabel {
    color: #c0c0c0;
}

/* radio */

.AppRadio {
}

.AppRadioLabel {
    color: #333333;
    font-size: 14px;
}

.AppRadioDisabled .AppRadioLabel {
    color: #c0c0c0;
}

/* select */

.AppSelect {
    display: block;
    font-size: 16px;
    cursor: pointer;
    /*font-family: sans-serif;*/
    /*font-weight: 700;*/
    color: #333;
    line-height: 1.3;
    padding: 0 20px 1px 0;
    /*padding: .6em 1.4em .5em .8em;*/
    /*width: 100%;*/
    /*max-width: 100%;*/
    box-sizing: border-box;
    margin: 0;
    border: 0;
    text-indent: -2px;
    border-bottom: 1px solid #333333;
    /*box-shadow: 0 1px 0 1px rgba(0,0,0,.04);*/
    /*border-radius: .5em;*/
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 3px top 50%, 0 0;
    background-size: .65em auto, 100%;
}
.AppSelect::-ms-expand {
    display: none;
}
.AppSelectDisabled {
    color: #c0c0c0;
    border-bottom-color: #c0c0c0;
}
.AppSelect:hover {
    /*border-color: #888;*/
}
.AppSelect:focus {
    /*border-color: #aaa;*/
    /*box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);*/
    /*box-shadow: 0 0 0 3px -moz-mac-focusring;*/
    outline: none;
}
.AppSelect:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
.AppSelect:focus option {
    /*font-weight: normal;*/
}

.AppSecurityCode {
}

.AppSecurityCodeDigit {
    border: 1px solid #7f7f7f;
    background-color: transparent;
    font-size: 24px;
    padding: 10px;
    margin: 1px 5px;
    width: 1em;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
}

.AppSecurityCodeDigit:focus {
    outline: none;
    border: 2px solid #7f7f7f;
    margin: 0 4px;
}

.AppPasswordValid {
    margin-left: 20px;
    padding: 10px;
}

.AppPasswordValidTitle {
    font-family: Lato-Bold, sans-serif;
    margin-bottom: 10px;
}

.AppPasswordValid table th {
    vertical-align: top;
    padding: 2px 5px 2px 0;
}

.AppPasswordValid table td {
    vertical-align: top;
    padding: 2px 0;
}

/*Removes up and down arrows for number fields for MFA*/
.AppSecurityCode input[type=number]::-webkit-inner-spin-button,
.AppSecurityCode input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.AppSecurityCode input[type=number]{
    -moz-appearance: textfield;
}