.PaneFirstLogin {
    max-width: 475px;
    width: 100%;
}

.PaneFirstLogin .AppField input[type=text] {
    width: 100%;
}

.PaneFirstLogin .AppSelect {
    width: 100%;
}

.PaneFirstLogin .AppField {
    display: block;
    width: 100%;
}

.PaneFirstLogin .AppField input[type=text] {
    display: block;
    width: 100%;
}

.PaneFirstLogin .AppControls {
    margin: 30px 10px 0 10px;
}

.PaneFirstLogin button {
    display: inline-block;
    max-width: 250px;
}

.PaneFirstLoginQuestion {
    margin: 20px 10px 0 10px;
}