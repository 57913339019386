.DialogCloseBtn {
    fill: #666666;
    width: 20px;
    height: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: transform 0.1s linear;
}
.DialogCloseBtn:hover {
    transform: scale(1.25);
}
.DialogCloseBtn:active {
    transform: scale(1);
}

.TrustedDialog {
    border: 1px solid #a0a0a0;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    text-align: left;
}

.TrustedDialogBody {
    max-width: 550px;
    padding: 0 20px 20px 20px;
}

.TrustedDialogErrorBody {
    max-width: 525px;
    padding: 0 20px 20px 20px;
}

.TrustedDialogSection {
    font-size: 14px;
    margin: 10px 0 20px 0;
}

.TrustedDialogNotice {
    margin-top: 10px;
    background: transparent url('../img/icon/infoIcon.svg') no-repeat 0 0;
    background-size: 18px 18px;
    min-height: 18px;
}

.TrustedDialogNoticeText {
    font-family: Lato-Bold, sans-serif;
    font-size: 13px;
    margin-left: 23px;
}

.TrustedDialog button.rounded {
    display: inline-block;
    width: auto;
    padding: 0 20px;
    /*width: 150px;*/
}

.TrustedDialog button.outline {
    display: inline-block;
    width: auto;
    padding: 0 20px;
    /*width: 150px;*/
}

.TrustedDialogFooter {
    margin-top: 30px;
}

.TrustedDialogFooter svg {
    /*margin-left: 50px;*/
}